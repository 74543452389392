import React from 'react'
import { PreviewImagesContainer, PreviewImage, PreviewContainer } from './styles'
import image1 from '../../images/samples/sample1.png'
import image2 from '../../images/samples/sample2.png'
import image3 from '../../images/samples/sample3.png'
import image4 from '../../images/samples/sample4.png'
import image5 from '../../images/samples/sample5.png'
import image6 from '../../images/samples/sample6.png'

function SampleToasts() {
  return (
    <PreviewContainer>
      <PreviewImagesContainer>
        <PreviewImage src={image1}></PreviewImage>
        <PreviewImage src={image2}></PreviewImage>
        <PreviewImage src={image3}></PreviewImage>
        <PreviewImage src={image4}></PreviewImage>
        <PreviewImage src={image5}></PreviewImage>
        <PreviewImage src={image6}></PreviewImage>

        <PreviewImage src={image1}></PreviewImage>
        <PreviewImage src={image2}></PreviewImage>
        <PreviewImage src={image3}></PreviewImage>
        <PreviewImage src={image4}></PreviewImage>
        <PreviewImage src={image5}></PreviewImage>
        <PreviewImage src={image6}></PreviewImage>

        <PreviewImage src={image1}></PreviewImage>
        <PreviewImage src={image2}></PreviewImage>
        <PreviewImage src={image3}></PreviewImage>
        <PreviewImage src={image4}></PreviewImage>
        <PreviewImage src={image5}></PreviewImage>
        <PreviewImage src={image6}></PreviewImage>

        <PreviewImage src={image1}></PreviewImage>
        <PreviewImage src={image2}></PreviewImage>
        <PreviewImage src={image3}></PreviewImage>
        <PreviewImage src={image4}></PreviewImage>
        <PreviewImage src={image5}></PreviewImage>
        <PreviewImage src={image6}></PreviewImage>
      </PreviewImagesContainer>
    </PreviewContainer>
  )
}

export default SampleToasts
