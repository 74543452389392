import React from 'react'
import './stylesheet.css'
import GlobalStyle from './GlobalStyle'
import NavBar from './components/NavBar'
import Hero from './components/Hero'
import Genesis from './components/Genesis'
import SampleToasts from './components/SampleToasts'
import Roadmap from './components/Roadmap'

function App() {
  return (
    <>
      <GlobalStyle />
      <NavBar />
      <Hero />
      <Genesis />
      <SampleToasts />
      <Roadmap />
    </>
  )
}

export default App
