import React from 'react'
import { GenesisContainer, GenesisHeader, GenesisText } from './styles'

function Genesis() {
  return (
    <GenesisContainer id="genesis">
      <GenesisHeader>Cryptoast Genesis</GenesisHeader>
      <GenesisText>
        Humankind has loved bread for many millennia. In recent decades, however, God has noticed
        gluten being unjustly persecuted, creating a schism throughout our society. Gluten-eating
        brother pit against gluten-free sister. This divide is threatening the unity of humanity and
        the very fabric of society. <br></br> To reunite our civilization and restore peace, God has
        sent Cryptoasts to the world&apos;s toasters to absolve us of our gluten sin-sitivities.
        Will God&apos;s holy mission be successful? Join the blessed Discord and follow the
        righteous Twitter for regular disseminations and updates to find out!
      </GenesisText>
      {/* 
      <GenesisStatsContainer>
        <GenesisStat>
          <GenesisStatLabel>Presale:</GenesisStatLabel>
          <GenesisStatData>Mar 8 2022</GenesisStatData>
        </GenesisStat>
        <GenesisStat>
          <GenesisStatLabel>Public sale:</GenesisStatLabel>
          <GenesisStatData>Mar 9 2022</GenesisStatData>
        </GenesisStat>
      </GenesisStatsContainer>
      */}
    </GenesisContainer>
  )
}

export default Genesis
