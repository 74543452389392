import { createGlobalStyle } from 'styled-components'
import backgroundImage from './images/bg.svg'

const GlobalStyle = createGlobalStyle`
  html {
    font-family: GothamPro;
    background-image: url(${backgroundImage});
    background-color: #D1E3EA;
    background-position: center top;
    background-size: auto auto;
    background-repeat: no-repeat;
    background-origin: content-box;
    scroll-behavior: smooth;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 25px;
    height: 20px;
    left: 30px;
    top: 54px;

    transition: all 250ms;

    @media only screen and (min-width: 800px) {
      display: none;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: black;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    opacity: 0.2;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #76A5BA;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    color: white;
    text-decoration: none;
    margin-bottom: 20px;

    &:hover: {
      cursor: pointer;
    }

  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`

export default GlobalStyle
