import React from 'react'
import atomImage from './images/roadmap/atom.svg'
import shopImage from './images/roadmap/shop.svg'
import flagImage from './images/roadmap/flag.svg'
import toastImage from './images/roadmap/toast.svg'
import worshipImage from './images/roadmap/worship.svg'

export const SOCIAL_LINKS = {
  TWITTER: 'https://twitter.com/CrypToastedNFT',
  DISCORD: 'https://discord.gg/guvCzSa9'
}

export const FAQ_ENTRIES = [
  {
    question: 'What is the Four Letter Words NFT collection?',
    answer: (
      <>
        This collection is made up of 4,444 unique photos of four-letter words taken by filmmaker
        Brett Simon. These NFTs are a celebration of bad words and good ones, a conversation between
        old and new media, and a catalyst for the creation of a community of word owners<br></br>
        <br></br>This collection of 4,444 Four Letter Words will be deployed on the Ethereum
        blockchain as ERC-721 tokens.
      </>
    )
  },
  {
    question: 'What inspired this collection?',
    answer: (
      <>
        This collection of NFTs is inspired by the first thing I ever collected: dirty words. When I
        was eight, I collected four-letter words like they were Pokemon cards, hunting them down on
        bathroom walls, trading them with friends, and stealing them from grownups. Late at night, I
        would whisper every word in my collection over and over like a secret spell. And the words
        made things happen. They got me in and out of trouble, cracked up a crush, and accidentally
        made a friend cry.
        <br></br>
        <br></br>
        Hoping to clean up my language, my parents tricked me into thinking the word “rill” was
        filthier than “fuck” and must never be used. I immediately passed it on to my friends, and
        by the end of the week, it was our curse of choice. My parents eventually came clean, but by
        then the word really was dirty to me and my friends. Even better, it was ours. My little
        brain was blown: Words were powerful, but we gave words their power. Words shaped us, but we
        could shape them.
        <br></br>
        <br></br>
        My fascination with words inspired this NFT series. I wanted to explore how words could
        evolve within web3, and see how the old medium and the new one relate to one another. I was
        curious how the physical properties that I love about print could be conjured in code. I
        wondered what it would mean to own and collect words in a digital wallet. I also wanted to
        see if these words could bring us together, if we could collectively shape their value, and
        if we, as a community, could give these words new meaning.
      </>
    )
  },
  {
    question: 'How is the Four Letter Words project giving back to the community?',
    answer: (
      <>
        Fellow readers are getting harder to find. Print is in decline. Four Letter Words will build
        a community of writers, artists, readers, and NFT collectors to share work, read books
        together, organize events, and collaborate on print publications. These community projects
        will be supported by a portion of secondary sales.
        <br></br>
        <br></br>
        Another portion will be donated to public libraries. Like these NFTs; we consider public
        libraries to be celebrations of the printed word, and another bridge between the old and the
        new, providing everyone with access to books and to computers.
      </>
    )
  },
  {
    question: 'When will the Four Letter Words collection launch?',
    answer: (
      <>
        This project will be minting in April! Follow our{' '}
        <a href={SOCIAL_LINKS.TWITTER} target="_blank" rel="noreferrer">
          Twitter
        </a>{' '}
        and{' '}
        <a href={SOCIAL_LINKS.DISCORD} target="_blank" rel="noreferrer">
          Discord
        </a>{' '}
        for updates on official launch dates.
      </>
    )
  },
  {
    question: 'What is the total supply?',
    answer: <>The total supply is 4,444.</>
  },
  {
    question: 'How do I mint the Four Letter words collection?',
    answer: (
      <>
        <b>Step 1:</b> Buy some Ethereum (ETH) on Coinbase.
        <br></br>
        <b>Step 2:</b> Create a Metamask account. This an ethereum digital wallet and what you’ll
        use to buy and store your NFTs.
        <br></br>
        <b>Step 3:</b> Send your ETH from Coinbase to your digital wallet.
        <br></br>
        <b>Step 4:</b> Once Four Letter Words is available for sale, connect your wallet to our mint
        site and click the mint button!
        <br></br>
        <br></br>
        Note: Purchasing an NFT requires a variable transaction charge (“gas fee”), so make sure you
        have a little extra ETH in your wallet to cover that cost. There are more in-depth guides to
        minting, if you’re in need of more support.
        <a href="http://cryptocoven.xyz" target="_blank" rel="noreferrer">
          Crypto Coven has a great guide!
        </a>
      </>
    )
  },
  {
    question: 'How do I get onto the MintList for Presale access?',
    answer: (
      <>
        We will be partnering with several NFT communities that we admire to provide presale access.
        We will also be sharing opportunities to get on the MintList on{' '}
        <a href={SOCIAL_LINKS.TWITTER} target="_blank" rel="noreferrer">
          Twitter
        </a>{' '}
        and in our{' '}
        <a href={SOCIAL_LINKS.DISCORD} target="_blank" rel="noreferrer">
          Discord
        </a>
        !
      </>
    )
  },
  {
    question: 'Is there a launch roadmap?',
    answer: (
      <>
        We have released a preliminary roadmap through Q3. Check it out <a href="#roadmap">here.</a>{' '}
        We will continue to add to our roadmap based upon interests from the community. Join our
        <a href={SOCIAL_LINKS.DISCORD} target="_blank" rel="noreferrer">
          Discord
        </a>{' '}
        and follow us on{' '}
        <a href={SOCIAL_LINKS.TWITTER} target="_blank" rel="noreferrer">
          Twitter
        </a>{' '}
        to stay connected as our roadmap evolves.
      </>
    )
  },
  {
    question: 'Are all Four Letter Words dirty?',
    answer: (
      <>
        “Dirty” is in the eye of the beholder! But we have included a “Dirty” property to identify
        words that flirt with filth. ;) Most of the words in the collection are not dirty.{' '}
      </>
    )
  },
  {
    question: 'Is every word in the collection unique? ',
    answer: (
      <>
        Each photo is unique but many words have been shot more than once. We will release a rarity
        table after launch.
      </>
    )
  },
  {
    question: 'What are my cc0 rights for my NFT?',
    answer: <>Owners have the complete rights to print or use their NFT in any way they choose.</>
  }
]

export const ROADMAP_STEPS = [
  {
    date: 'Q2',
    title: 'Initial Mint',
    description: 'Challa-lujha! CrypToasts descend from the heavens.',
    image: atomImage
  },
  {
    date: 'Q2',
    title: 'Bread making',
    description: `Let's get this bread! CrypToast Virtual Bread Making Class.`,
    image: toastImage
  },
  {
    date: 'Q2',
    title: 'Blessed Bakery',
    description: 'Opening merch shop (including special edition merch for rare Jesus holders).',
    image: shopImage
  },
  {
    date: 'Q3',
    title: 'Sourdough Shrine*',
    description: 'Build a real world place of gluten worship in Joshua Tree.',
    image: worshipImage
  },
  {
    date: 'Q3',
    title: 'Toast-to-Toast Cross Country Tour*',
    description: 'Fun, toast-riddled events across the country.',
    image: flagImage
  }
]
