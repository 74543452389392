import styled from 'styled-components'

export const GenesisContainer = styled.div`
  display: flex;
  width: 560px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;

  @media only screen and (max-width: 600px) {
    width: 90%;
    margin-top: 60px;
  }
`

export const GenesisHeader = styled.h1`
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  color: #15212d;
  text-align: center;
`

export const GenesisText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #15212d;
  text-align: center;
`

export const GenesisStatsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const GenesisStat = styled.div`
  width: 278px;
  height: 48px;
  border-radius: 4px;
  background-color: #e1efef;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const GenesisStatLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #345779;
  margin-left: 12px;
`

export const GenesisStatData = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #15212d;
  margin-right: 12px;
`
