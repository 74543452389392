import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import {
  ConnectWalletButton,
  NavBarContainer,
  NavBarLink,
  NavBarLinksContainer,
  NavBarSocialsContainer,
  SocialIconsContainer,
  SocialImage
} from './styles'
import discordmage from '../../images/socials/discord.svg'
import twitterImage from '../../images/socials/twitter.svg'
import { newTab } from '../../utils'
import { SOCIAL_LINKS } from '../../constants'

function NavBar() {
  return (
    <>
      <Menu>
        <a className="menu-item" href="#genesis">
          Genesis
        </a>
        <a className="menu-item" href="#roadmap">
          Roadmap
        </a>
        <SocialIconsContainer>
          <SocialImage onClick={() => newTab(SOCIAL_LINKS.DISCORD)} src={discordmage} />
          <SocialImage onClick={() => newTab(SOCIAL_LINKS.TWITTER)} src={twitterImage} />
        </SocialIconsContainer>
      </Menu>
      <NavBarContainer>
        <NavBarSocialsContainer>
          <SocialImage onClick={() => newTab(SOCIAL_LINKS.DISCORD)} src={discordmage} />
          <SocialImage onClick={() => newTab(SOCIAL_LINKS.TWITTER)} src={twitterImage} />
        </NavBarSocialsContainer>
        <NavBarLinksContainer>
          <NavBarLink href="#genesis">Genesis</NavBarLink>
          <NavBarLink href="#roadmap">Roadmap</NavBarLink>
          <ConnectWalletButton>Connect Wallet</ConnectWalletButton>
        </NavBarLinksContainer>
      </NavBarContainer>
    </>
  )
}

export default NavBar
