import styled from 'styled-components'

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
  align-items: center;
`

export const HeroLogoImage = styled.img`
  width: 163px;
  height: 38px;
`

export const HeroHeader = styled.h1`
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  color: #15212d;
  text-align: center;
`

export const HeroToastImage = styled.img`
  width: 300px;
  height: 350px;
  margin-right: -25px;
`

export const HeroMintedStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`

export const HeroMintedStatsHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #345779;
  text-align: center;
`

export const HeroMintedStatsData = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: #15212d;
  text-align: center;
`

export const HeroMintPriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 20px;
`

export const HeroMintPriceHeader = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #345779;
  justify-content: center;
  align-items: center;
`

export const HeroMintPriceData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #15212d;
`

export const HeroMintPriceLogo = styled.img`
  width: 10px;
  height: 16px;
  margin-left: 12px;
  margin-right: 4px;
`

export const HeroMintCountLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #345779;
  margin-bottom: 4px;
`

export const HeroMintCountInput = styled.input`
  width: 200px;
  height: 40px;
  padding: 8px 16px 8px 17px;
  border-radius: 4px;
  border: solid 1px #97c6ca;
  background-color: #eaf3f3;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #15212d;
  padding: 0px;
  padding-left: 4px;
  padding-right: 4px;
`

export const MintButton = styled.button`
  margin-top: 20px;
  width: 200px;
  height: 60px;
  object-fit: contain;
  border-radius: 30px;
  box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.08);
  border-style: solid;
  border-width: 4px;
  border-image-slice: 1;
  background-image: linear-gradient(to right, rgba(0, 16, 101, 0.8) 1%, rgba(0, 67, 110, 0.8) 100%),
    linear-gradient(to right, #001065 1%, #00436e 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.17;
  color: #fff;

  transition: opacity 250ms;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`
