import styled from 'styled-components'

export const NavBarContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 40px;
  justify-content: space-between;
`
export const NavBarSocialsContainer = styled.div`
  display: flex;
  margin-left: 120px;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`

export const SocialImage = styled.img`
  margin-right: 20px;

  transition: transform 250ms;
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
`

export const SocialIconsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  margin-left: 40px;

  svg {
    margin-right: 16px;
    transition: all 250ms;

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }

  @media only screen and (max-width: 800px) {
    display: none;
    margin-left: 0px;
    position: absolute;
    bottom: 0px;
  }
`

export const NavBarLinksContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 120px;

  @media only screen and (max-width: 800px) {
    width: 100%;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 5px;
  }
`

export const NavBarLink = styled.a`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #15212d;
  margin-right: 20px;
  text-decoration: none;

  @media only screen and (max-width: 800px) {
    display: none;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }
`

export const ConnectWalletButton = styled.button`
  width: 167px;
  height: 40px;
  object-fit: contain;
  border-radius: 30px;
  border-style: solid;
  border-width: 4px;
  border-image-slice: 1;
  background-image: linear-gradient(to right, rgba(0, 16, 101, 0.8) 1%, rgba(0, 67, 110, 0.8) 100%),
    linear-gradient(to right, #001065 1%, #00436e 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #fff;

  transition: opacity 250ms;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`
