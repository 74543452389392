import React from 'react'
import { ROADMAP_STEPS } from '../../constants'
import {
  RoadmapContainer,
  RoadmapDateBubble,
  RoadmapHeader,
  RoadmapStepBox,
  RoadmapStepDescription,
  RoadmapStepsContainer,
  RoadmapStepHeader,
  RoadMapTextContainer,
  RoadMapBoxContainer,
  RoadmapStepImage,
  RoadmapTimeline,
  RoadmapDot
} from './styles'

function Roadmap() {
  return (
    <RoadmapContainer>
      <RoadmapHeader id="roadmap">Roadmap</RoadmapHeader>
      <RoadmapStepsContainer>
        <RoadmapTimeline />
        {ROADMAP_STEPS.map((step, i) => (
          <>
            <RoadmapDateBubble left={i % 2 === 0}>
              {step.date} <RoadmapDot left={i % 2 === 0} />
            </RoadmapDateBubble>
            <RoadmapStepBox left={i % 2 === 0}>
              <RoadMapBoxContainer>
                <RoadmapStepImage src={step.image}></RoadmapStepImage>
                <RoadMapTextContainer>
                  <RoadmapStepHeader>{step.title}</RoadmapStepHeader>
                  <RoadmapStepDescription>{step.description}</RoadmapStepDescription>
                </RoadMapTextContainer>
              </RoadMapBoxContainer>
            </RoadmapStepBox>
          </>
        ))}
      </RoadmapStepsContainer>
    </RoadmapContainer>
  )
}

export default Roadmap
