import styled from 'styled-components'

export const RoadmapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
`

export const RoadmapHeader = styled.h1`
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  color: #15212d;
`

export const RoadmapStepsContainer = styled.div`
  display: inline-block;
  position: relative;
`

export const RoadmapDateBubble = styled.div`
  position: relative;
  display: inline-block;
  padding: 6px 20px;
  border-radius: 20px;
  background-color: #293b7f;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  width: auto;
  left: ${(props) => (props.left ? '-200px' : '200px')};
  margin-bottom: 4px;

  @media only screen and (max-width: 800px) {
    left: 0px;
  }
`

export const RoadmapStepBox = styled.div`
  position: relative;
  padding: 12px;
  border-radius: 4px;
  background-color: #e1efef;
  width: 300px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: normal;
  left: ${(props) => (props.left ? '-200px' : '200px')};
  @media only screen and (max-width: 800px) {
    left: 0px;
    margin-bottom: 20px;
  }
`

export const RoadmapStepHeader = styled.div`
  font-size: 18px;
  color: #15212d;
`

export const RoadmapStepDescription = styled.div`
  font-size: 14px;
  color: #345779;
`

export const RoadmapDot = styled.div`
  position: absolute;
  left: ${(props) => (props.left ? '357px' : '-43px')};
  top: 12px;
  width: 8px;
  height: 8px;
  background-color: #293b7f;
  border-radius: 50%;

  @media only screen and (max-width: 800px) {
    left: -23px;
  }
`

export const RoadmapTimeline = styled.div`
  height: 100%;
  position: absolute;
  width: 2px;
  border-radius: 100px;
  background-color: #293b7f;
  left: calc(50% - 2px);

  @media only screen and (max-width: 800px) {
    left: -20px;
  }
`
export const RoadMapTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const RoadMapBoxContainer = styled.div`
  display: flex;
`

export const RoadmapStepImage = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-right: 12px;
  margin-top: 6px;
`
