import styled from 'styled-components'

export const PreviewContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  margin-top: 120px;
  overflow: hidden;

  @media only screen and (max-width: 900px) {
    margin-top: 20px;
  }
`

export const PreviewImagesContainer = styled.div`
  display: flex;
  margin-top: 20px;
  transition: all 250ms;

  @keyframes slowscroll {
    0% { 
      transform: translateX(0%); 
    }
    100% { 
      transform: translateX(50%); 
    }
  }

  animation: slowscroll 120s infinite;
  animation-timing-function: linear;

  &:hover {
    animation-play-state: paused;


    img {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }

  }
}

`

export const PreviewImage = styled.img`
  width: 220px;
  height: 220px;
  margin-left: 6px;
  margin-right: 6px;
  transition: all 250ms;
  border-radius: 4px;

  &:hover {
    transform: scale(1.1);
  }

  @media only screen and (max-width: 900px) {
    width: 100px;
    height: 100px;
    margin-left: 3px;
    margin-right: 3px;
  }
`
