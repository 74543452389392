import React from 'react'
import {
  HeroContainer,
  HeroHeader,
  HeroLogoImage,
  HeroMintCountInput,
  HeroMintCountLabel,
  HeroMintedStatsContainer,
  HeroMintedStatsData,
  HeroMintedStatsHeader,
  HeroMintPriceContainer,
  HeroMintPriceData,
  HeroMintPriceHeader,
  HeroMintPriceLogo,
  HeroToastImage,
  MintButton
} from './styles'
import logoImage from '../../images/logos/logo.svg'
import toastImage from '../../images/logos/toast.png'
import ethImage from '../../images/logos/eth.svg'

function Hero() {
  return (
    <HeroContainer>
      <HeroLogoImage src={logoImage} />
      <HeroHeader>NFT Collection</HeroHeader>
      <HeroToastImage src={toastImage} />
      <HeroMintedStatsContainer>
        <HeroMintedStatsHeader>Total minted:</HeroMintedStatsHeader>
        <HeroMintedStatsData>0 / 7,777</HeroMintedStatsData>
      </HeroMintedStatsContainer>
      <HeroMintPriceContainer>
        <HeroMintPriceHeader>Price:</HeroMintPriceHeader>
        <HeroMintPriceLogo src={ethImage} />
        <HeroMintPriceData>0.07</HeroMintPriceData>
      </HeroMintPriceContainer>
      <HeroMintCountLabel>Quantity:</HeroMintCountLabel>
      <HeroMintCountInput type="number" min={1} max={7}></HeroMintCountInput>
      <MintButton>Mint</MintButton>
    </HeroContainer>
  )
}

export default Hero
